export default {
  en: {
    introTitle:
      "If you are here, it must mean that you are interested in our offer.",
    introText:
      "Fastpack is located in Copenhagen, but we serve customers from all over Europe. You can contact us in English, Danish and Polish. ",
    introCta: "See how we work and write to us.",
    hiwTitle: "How does it work?",
    hiwSteps: [
      {
        title: "1. Fill in the form",
        bullets: [
          "Write the type of packaging you need.",
          "linkProduct",
          "If you want to receive a free visualization of packaging, attach your logo."
        ]
      },
      {
        title: "2. Send message",
        bullets: [
          "A dedicated consultant will contact you to agree on the details.",
          "Regardless of the industry in which you operate, the consultant will analyse your needs and provide additional solutions.",
          "You will receive the initial design and the cost estimate after 2 days."
        ]
      },
      {
        title: "3. Receive the packaging ",
        bullets: [
          "When you accept the terms of the contract and the design—we send it for production.",
          "We will manufacture and deliver your packaging as soon as the given product is available.",
          "We also make sure that our logistics services are good. We run statistics and analyses, so we know when you need the next delivery from us. You will receive information from us in advance."
        ]
      }
    ],
    textToLinkProduct: [
      "In the ",
      "PRODUCTS",
      " tab you can see sample packaging we produce."
    ],
    formTitle: "Receive a free visualization!",
    formIntro:
      "At Fastpack, we design and manufacture personalized packaging for your business.",
    formBullets: [
      "Fill in the form.",
      "Tell us what you need.",
      "Attach your logo."
    ],
    formInfo:
      "<b>You will receive a free visualization</b> of packaging with your print on it.",
    contactDetails: "Contact details",
    contactPerson: "Rafał Stępień",
    contactPhone: "+45 51945484",
    contactEmail: "kontakt@fastpack.dk",
    websiteAddress: "www.fastpack.dk",
    formDays: "Mon. - Fri.",
    formHours: "9:00am - 5:00pm",
    footer: {
      navigation: "Navigation",
      certificates: "Certificates",
      followUs: "Follow us on",
      contact: "Contact"
    },
    formFields: {
      name: "Name", 
      email: "Email", 
      phone: "Phone number", 
      message: "Message", 
      mandatory: "* mandatory fields", 
      sendButton: "Send a message", 
      sentTitle: "Message sent!",
      sentText: "Thank you for your interest in our offer. Within 24 hours, your personal consultant will contact you to discuss project details. <br /> Check your e-mail. If you did not find the message from Fastpack.dk in your inbox, check the [OFFERS] or [SPAM] folder. <br /> Add us as a trusted sender to make sure our messages reach you efficiently.<br /><br />Do you want to contact us via another channel? No problem! You can contact us by phone or via Facebook Messenger.<br /><br /> Best regards, <br /> The Fastpack Team"
    }
  },
  pl: {
    introTitle:
      "Jeżeli jesteś tutaj, to znaczy, że zainteresowała Cię nasza oferta.",
    introText:
      "Fastpack mieści się w Kopenhadze, ale obsługujemy klientów z całej Europy. Porozumiewamy się w języku angielskim, duńskim oraz polskim.",
    introCta: "Sprawdź jak to działa i napisz do nas.",
    hiwTitle: "Jak to działa?",
    hiwSteps: [
      {
        title: "1. Wypełnij formularz",
        bullets: [
          "Napisz, jakich opakowań potrzebujesz.",
          "linkProduct",
          "Jeśli chcesz otrzymać bezpłatną wizualizację opakowań, załącz swoje logo."
        ]
      },
      {
        title: "2. Wyślij wiadomość",
        bullets: [
          "Twój dedykowany konsultant skontaktuje się z Tobą w celu uzgodnienia szczegółów.",
          "Niezależnie od branży, w której działasz, dokona analizy Twoich potrzeb i przedstawi dodatkowe rozwiązania.",
          "Wstępny projekt oraz kosztorys otrzymasz już po 2 dniach."
        ]
      },
      {
        title: "3. Odbierz opakowania",
        bullets: [
          "Gdy zaakceptujesz  projekt - wysyłamy go do produkcji.",
          "Wyprodukujemy i dostarczymy Twoje opakowania tak szybko, jak to możliwe dla danego produktu.",
          "Dbamy również o dobrą logistykę. Prowadzimy statystyki i analizy, dzięki czemu wiemy, kiedy potrzebujesz kolejnej dostawy. Odpowiednio wcześniej otrzymasz od nas informację."
        ]
      }
    ],
    textToLinkProduct: [
      "W zakładce ",
      "PRODUKTY",
      " możesz zobaczyć przykładowe opakowania."
    ],
    formTitle: "Skontaktuj się z nami",
    formIntro:
      "W Fastpack projektujemy i produkujemy spersonalizowane opakowania dla Twojego biznesu.",
    formBullets: [
      "Wypełnij formularz.",
      "Napisz nam, czego potrzebujesz.",
      "Załącz swoje logo."
    ],
    formInfo:
      "<b>Otrzymasz bezpłatną wizualizację</b> Twoich opakowań z nadrukiem.",
    contactDetails: "Dane kontaktowe",
    contactPerson: "Rafał Jan Stępień",
    contactPhone: "+48 531 117 395",
    contactEmail: "kontakt@fastpack.dk",
    websiteAddress: "www.fastpack.dk",
    formDays: "Pon. - Pt.",
    formHours: "9:00 - 5:00",
    footer: {
      navigation: "Menu",
      certificates: "Certifikaty",
      followUs: "Dołącz do nas",
      contact: "Kontakt"
    },
    formFields: {
      name: "Name", 
      email: "Email", 
      phone: "Phone number", 
      message: "Message", 
      mandatory: "* mandatory fields", 
      sendButton: "Send a message", 
      sentTitle: "Message sent!",
      sentText: "Thank you for your interest in our offer. Within 24 hours, your personal consultant will contact you to discuss project details. <br /> Check your e-mail. If you did not find the message from Fastpack.dk in your inbox, check the [OFFERS] or [SPAM] folder. <br /> Add us as a trusted sender to make sure our messages reach you efficiently.<br /><br />Do you want to contact us via another channel? No problem! You can contact us by phone or via Facebook Messenger.<br /><br /> Best regards, <br /> The Fastpack Team"
    }
  },
  dk: {
    introTitle: "Er du kommet hertil, må vores tilbud have interesseret dig.",
    introText:
      "Vi holder til i København, men vi servicerer kunder fra hele Europa. Vi supporter på følgende sprog: engelsk, dansk og polsk. ",
    introCta: "Tjek os ud og  skriv til os.",
    hiwTitle: "Hvordan fungerer det?",
    hiwSteps: [
      {
        title: "1. Udfyld formularen",
        bullets: [
          "Skriv, hvilken type emballage du har brug for.",
          "linkProduct",
          "Hvis du er interesseret i at modtage et gratis udkast til din emballage, så vedhæft dit logo."
        ]
      },
      {
        title: "2. Send besked",
        bullets: [
          "Vores dedikerede konsulent vil derefter kontakte dig med henblik på specifikationer og detaljer.",
          "Uanset din branche vil vi analysere dine emballagebehov og komme med forslag og løsninger.",
          "Herefter vil du inden for 2 arbejdsdage, modtage et designudkast samt et prisestimat."
        ]
      },
      {
        title: "3. Modtagelse af varer",
        bullets: [
          "Når du har accepteret handelsbetingelserne samt design/produktions projektet – sætter vi varerne i produktion.",
          "Vi fremstiller og leverer din emballage så hurtigt som muligt, alt efter emballagetype.",
          "For os er logistikken vigtig. Derfor laver vi  statistik og analyse på produktforbruget, så vi ved, hvornår du har brug for næste levering. Vi giver dig besked, når det er tid til at bestille igen."
        ]
      }
    ],
    textToLinkProduct: [
      "I fanen  ",
      "PRODUKTER",
      " kan du se eksempler på emballagetyper, vi producerer."
    ],
    formTitle: "Modtag gratis designudkast/mockup",
    formIntro:
      "Hos Fastpack designer og producerer vi emballage til din virksomhed.",
    formBullets: [
      "Udfyld formularen",
      "Skriv til os, hvad du har brug for",
      "Vedhæft dit logo"
    ],
    formInfo: "Du modtager et gratis designudkast/mockup.",
    contactDetails: "Contact details",
    contactPerson: "Rafał Stępień",
    contactPhone: "+45 51945484",
    contactEmail: "kontakt@fastpack.dk",
    websiteAddress: "www.fastpack.dk",
    formDays: "Vi har åbent fra mandag til fredag mellem kl",
    formHours: "9:00 og 17:00",
    footer: {
      navigation: "Navigation",
      certificates: "Certifikater",
      followUs: "Find os på",
      contact: "Kontakt"
    },
    formFields: {
      name: "Fornavn", 
      email: "E-mail", 
      phone: "Telefonnummer (valgfrit)", 
      message: "Meddelelsens indhold", 
      mandatory: "* obligatoriske felter", 
      sendButton: "Send besked", 
      sentTitle: "Tak fordi du tog dig tid til at kontakte os.",
      sentText: "Indenfor 24 timer vil en af vores dedikerede konsulenter kontakte dig med henblik på specifikationer og detaljer vedr. projektet.<br /> Tjek din mail. Hvis du ikke kan se, at du har modtaget en mail fra Fastpack.dk, tjek din SPAM mappe. Tilføj os som betroet afsender, så du kan modtage mail fra os. <br /> Har du brug for at kontakte os på anden vis? Intet problem! Du kan ringe til os eller bruge Facebook. <br /><br /> Venlig hilsen,<br /> Fastpack teamet"
    }
  },
  es: {
    introTitle:
      "Si estás aquí, eso quiere decir que nuestra oferta te ha interesado.",
    introText:
      "Fastpack está ubicado en Copenhague pero prestamos servicios a clientes de toda Europa. Hablamos en inglés, danés y polaco.",
    introCta: "Descubre cómo trabajamos y escríbenos.",
    hiwTitle: "¿CÓMO FUNCIONA?",
    hiwSteps: [
      {
        title: "1. RELLENA EL FORMULARIO",
        bullets: [
          "Escríbenos qué tipo de envases necesitas.",
          "linkProduct",
          "Si deseas recibir una visualización de envases gratuita, adjunta el logo de tu marca."
        ]
      },
      {
        title: "2. MÁNDANOS UN MENSAJE",
        bullets: [
          "Tu asesor personal de servicio al cliente se pondrá en contacto contigo para discutir los detalles.",
          "Independientemente del tipo de tu negocio, analizará tus necesidades y te presentará diferentes soluciones.",
          "Recibirás la visualización inicial del proyecto y la estimación de costos en 2 días."
        ]
      },
      {
        title: "3.RECIBE LOS ENVASES",
        bullets: [
          "Cuando aceptes los términos del contrato y el diseño del proyecto, lo envíamos a la producción.",
          "Fabricaremos y te entregaremos tus envases tan pronto como lo sea posible dado las características del producto.",
          "Nos ocupamos de una buena logística. Gracias a las estadísticas y análisis que realizamos, sabemos cuándo necesitarás la próxima entrega. Te lo comunicaremos con antelación."
        ]
      }
    ],
    textToLinkProduct: [
      "En la pestaña ",
      "PRODUCTOS",
      " puedes ver los envases de muestra que fabricamos."
    ],
    formTitle: "Ponte en contacto con nosotros",
    formIntro:
      "En Fastpack diseñamos y fabricamos envases personalizados para tu negocio.",
    formBullets: [
      "Rellena el formulario.",
      "Escríbenos qué es lo que necesitas.",
      "Adjunta el logo de tu marca"
    ],
    formInfo:
      "<b>Recibirás la visualización gratuita de tus envases con estampado. ",
    contactDetails: "Contact details",
    contactPerson: "Rafał Stępień",
    contactPhone: "+45 51945484",
    contactEmail: "kontakt@fastpack.dk",
    websiteAddress: "www.fastpack.dk",
    formDays: "Mon. - Fri.",
    formHours: "9:00am - 5:00pm",
    footer: {
      navigation: "Navigation",
      certificates: "Certificates",
      followUs: "Follow us on",
      contact: "Contact"
    },
    formFields: {
      name: "Nombre", 
      email: "E-mail", 
      phone: "Teléfono", 
      message: "Mensaje", 
      mandatory: "* campo obligatorio", 
      sendButton: "Enviar mensaje", 
      sentTitle: "Mensaje enviado!",
      sentText: "Gracias por tu interés en nuestra oferta. Su asesor personal se pondrá en contacto contigo dentro de 24 horas para discutir los detalles del proyecto. <br /> Comprueba su correo electrónico. Si no te ha llegado ningún mensaje de Fastpack.dk en la bandeja de entrada, comprueba la carpeta PROMOCIONES o SPAM. <br /> Agréguenos a la lista de remitentes seguros para facilitar la recepción de mensajes de Fastpack.<br /><br /> ¿Quieres contactarnos por otro medio ? ¡Sin ningún problema! Puedes llamarnos al nuestro número de teléfono o contactarnos a través de Facebook Messenger.<br /><br /> Cordialmente, <br /> Equipo Fastpack"
    }
  },
  de: {
    introTitle:
      "Wenn Sie hier sind, bedeutet es, dass Sie an unserem Angebot interessiert wurden.",
    introText:
      "Fastpack befindet sich in Kopenhagen, aber wir bedienen Kunden aus ganz Europa. Wir kommunizieren in Englisch, Dänisch und Polnisch.",
    introCta: "Prüfen Sie, wie wir arbeiten, und schreiben Sie uns.",
    hiwTitle: "WIE FUNKTIONIERT ES?",
    hiwSteps: [
      {
        title: "1. FORMULAR AUSFÜLLEN",
        bullets: [
          "Schreiben Sie, welche Verpackung Sie benötigen.",
          "linkProduct",
          "Wenn Sie eine kostenlose Verpackungsvisualisierung wünschen, fügen Sie bitte Ihr Logo bei"
        ]
      },
      {
        title: "2. DIE VERPACKUNGEN ABHOLEN",
        bullets: [
          "Ihr Berater wird sich mit Ihnen in Verbindung setzen, um die Details zu vereinbaren.",
          "Unabhängig von der Branche, in der Sie tätig sind, wird er Ihre Bedürfnisse gründlich analysieren und zusätzliche Lösungen vorstellen.",
          "Schon nach 2 Tagen erhalten Sie ein vorläufiges Design und einen Kostenvoranschlag."
        ]
      },
      {
        title: "3. DIE VERPACKUNGEN ABHOLEN",
        bullets: [
          "Wenn Sie die Vertragsbedingungen und das Design akzeptieren, geben wir es an die Produktion weiter.",
          "Wir produzieren und liefern Ihre Verpackungen so schnell wie möglich für das jeweilige Produkt.",
          "Wir sorgen auch für eine gute Logistik. Wir führen Statistiken und Analysen durch, damit wir wissen, wann Sie die nächste Lieferung brauchen. Sie erhalten rechtzeitig eine Information von uns."
        ]
      }
    ],
    textToLinkProduct: [
      "Auf der Registerkarte ",
      "PRODUCTS",
      " sehen Sie Beispiele für Verpackungen, die wir herstellen."
    ],
    formTitle: "Kontaktieren Sie uns",
    formIntro:
      "Bei Fastpack entwerfen und fertigen wir personalisierte Verpackungen für Ihr Unternehmen.",
    formBullets: [
      "Füllen Sie das Formular aus.",
      "Schreiben Sie uns, was Sie brauchen.",
      "Fügen Sie Ihr Logo hinzu."
    ],
    formInfo:
      "<b>Sie erhalten eine kostenlose Visualisierung</b> Ihrer Verpackungen mit Aufdruck..",
    contactDetails: "Vorname",
    contactPerson: "Rafał Stępień",
    contactPhone: "+45 51945484",
    contactEmail: "kontakt@fastpack.dk",
    websiteAddress: "www.fastpack.dk",
    formDays: "Mon. - Fri.",
    formHours: "9:00am - 5:00pm",
    footer: {
      navigation: "Nachricht",
      certificates: "Pflichtfelder",
      followUs: "DATEI ANHÄNGEN",
      contact: "Nachricht senden"
    },
    formFields: {
      name: "Vorname", 
      email: "E-Mail-Adresse", 
      phone: "Telefonnummer", 
      message: "Nachricht", 
      mandatory: "* Pflichtfelder", 
      sendButton: "Nachricht senden", 
      sentTitle: "Nachricht gesendet!",
      sentText: "Vielen Dank für Ihr Interesse an unserem Angebot. Ihr persönlicher Berater wird Sie innerhalb von 24 Stunden kontaktieren, um die Details des Projekts zu besprechen.<br /> Prüfen Sie Ihren Posteingang. Wenn Sie keine Nachricht von Fastpack.dk in Ihrem Posteingang gefunden haben, überprüfen Sie den Ordner [ANGEBOTE] oder [SPAM]. <br /> Fügen Sie uns als vertrauenswürdigen Absender hinzu, um Nachrichten von uns problemlos zu empfangen.<br /><br /> Möchten Sie mit uns über einen anderen Kanal sprechen? Kein Problem! Sie können uns per Telefon oder über Facebook Messenger erreichen.<br /><br /> Mit freundlichen Grüßen <br /> Ihr Fastpack-Team"
    }
  },
  fr: {
    introTitle: "Si vous êtes ici, vous êtes intéressé par notre offre.",
    introText:
      "Fastpack est situé à Copenhague, mais nous servons des clients de toute l'Europe. Nous communiquons en anglais, danois et polonais.",
    introCta: "Vérifiez comment nous fonctionnons et écrivez-nous.",
    hiwTitle: "COMMENT ÇA MARCHE ?",
    hiwSteps: [
      {
        title: "1. REMPLISSEZ LE FORMULAIRE",
        bullets: [
          "Écrivez l'emballage dont vous avez besoin.",
          "linkProduct",
          "Si vous souhaitez une visualisation gratuite des emballages, veuillez joindre votre logo."
        ]
      },
      {
        title: "2. ENVOYER LE MESSAGE",
        bullets: [
          "Votre consultant dédié vous contactera pour convenir des détails.",
          "Quel que soit le secteur dans lequel vous évoluez, il analysera en profondeur vos besoins et vous présentera des solutions supplémentaires.",
          "Vous recevrez une conception préliminaire et une estimation détaillée des coûts après 2 jours."
        ]
      },
      {
        title: "3. RECEVEZ L'EMBALLAGE ",
        bullets: [
          "Une fois que vous acceptez les termes du contrat et la conception, nous l'envoyons à la production.",
          "Nous produirons et livrerons votre emballage dès que possible pour votre produit.",
          "Nous veillons également à une bonne logistique. Nous réalisons des statistiques et des analyses afin de savoir quand vous avez besoin d'une autre livraison. Nous vous en informerons à l'avance."
        ]
      }
    ],
    textToLinkProduct: [
      "Dans l'onglet",
      "PRODUITS",
      ", vous pouvez voir des exemples d'emballage que nous produisons."
    ],
    formTitle: "Contactez-nous",
    formIntro:
      "Chez Fastpack, nous concevons et fabriquons des emballages personnalisés pour votre entreprise.",
    formBullets: [
      "Remplissez le formulaire.",
      "Écrivez-nous ce dont vous avez besoin.",
      "Attachez votre logo."
    ],
    formInfo:
      "<b>Vous recevrez une visualisation gratuite</b> de votre emballage imprimé.",
    contactDetails: "Contact details",
    contactPerson: "Rafał Stępień",
    contactPhone: "+45 51945484",
    contactEmail: "kontakt@fastpack.dk",
    websiteAddress: "www.fastpack.dk",
    formDays: "Lun-ven",
    formHours: "9:00am - 5:00pm",
    footer: {
      navigation: "Navigation",
      certificates: "Certificates",
      followUs: "Follow us on",
      contact: "Contact"
    },
    formFields: {
      name: "Prénom", 
      email: "Courriel", 
      phone: "Numéro de téléphone", 
      message: "Votre message", 
      mandatory: "* champs obligatoires", 
      sendButton: "Envoyez un message", 
      sentTitle: "Message envoyé!",
      sentText: "Nous vous remercions de l'intérêt que vous portez à notre offre. Votre conseiller personnel vous contactera dans les 24 heures pour discuter des détails du projet. <br /> Check your e-mail. If you did not find the message from Fastpack.dk in your inbox, check the [OFFERS] or [SPAM] folder. <br /> Add us as a trusted sender to make sure our messages reach you efficiently.<br /><br /> Do you want to contact us via another channel? No problem! You can contact us by phone or via Facebook Messenger.<br /><br /> Best regards, <br /> The Fastpack Team"
    }
  }
};
