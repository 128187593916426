import React, { useContext } from "react";
import styles from "./Footer.module.css";
import { Col, Container, Image } from "react-bootstrap";
import { pageElements } from "../../shared/pageElements";
import contactPage from "../../shared/pages/contact";
import { getImgUrl, langPrefix } from "../../shared/scripts";
import { GlobalStateContext } from "../../context/GlobalContextProvider";
import { graphql, useStaticQuery, Link } from "gatsby";

const Footer = () => {
  const { language } = useContext(GlobalStateContext);
  const { nav } = pageElements[language];
  const { followUs, contact, navigation } = contactPage[language];

  const generateNavigation = () =>
    nav.map((e, i) => (
      <li key={`nav-link-${i}`} className={styles.link}>
        <Link
          key={`main-nav-${e.link}`}
          to={`${langPrefix(language)}${e.link}`}
        >
          {e.name}
        </Link>
      </li>
    ));

  const getAddress = () => {
    return language === "dk" ? (
      <>
        Frederiskborgvej 59 <br />
        2400 København N<br />
        Danmark
      </>
    ) : (
      <>
        {" "}
        Frederiskborgvej 59 <br /> 2400 Copenahegen N<br /> Denmark
      </>
    );
  };

  const { allFile } = useStaticQuery(
    graphql`
      query {
        allFile(
          sort: { fields: name, order: DESC }
          filter: { relativeDirectory: { eq: "icons" } }
        ) {
          edges {
            node {
              id
              name
              publicURL
            }
          }
        }
      }
    `
  );

  return (
    <div className={styles.self}>
      <Container className={styles.container}>
        <Col className={styles.item}>
          <Image src={getImgUrl("fastpack_logo_footer", allFile)} />
        </Col>
        <Col className={styles.item}>
          <h5>{navigation}</h5>
          <ul className={styles.navigation}>{generateNavigation()}</ul>
        </Col>
        {/* <Col className={styles.item}>
          <h5>{certificates}</h5>
          <Image src={getImgUrl("certyfikat", allFile)} />
        </Col> */}
        <Col className={styles.item}>
          <h5>{followUs}</h5>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={"https://www.facebook.com/fastpack.dk/"}
          >
            <Image
              className={styles.icon}
              src={getImgUrl("facebook", allFile)}
            />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={"https://www.instagram.com/fastpack.dk/"}
          >
            <Image
              className={styles.icon}
              src={getImgUrl("instagram", allFile)}
            />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={"https://pl.pinterest.com/fastpackdk/"}
          >
            <Image
              className={styles.icon}
              src={getImgUrl("pinterest_white", allFile)}
            />
          </a>
        </Col>
        <Col className={styles.item}>
          {" "}
          <h5>{contact}</h5>
          <p>
            <Image
              className={styles.icon}
              src={getImgUrl("phone-call-reversed", allFile)}
            />{" "}
            +45 51 94 54 84 <br />
          </p>
          <p>
            <Image
              className={styles.icon}
              src={getImgUrl("mail-reversed", allFile)}
            />{" "}
            <a href="mailto:kontakt@fastpack.dk">kontakt@fastpack.dk</a>
            <br />
            <br />
            {getAddress()}
          </p>
        </Col>
      </Container>
    </div>
  );
};

export default Footer;
